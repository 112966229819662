import React, { Component } from "react";
import Cleave from "cleave.js/react";

import { connect } from "react-redux";

import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";

import { getSignupInfo } from "../../actions/modules_action/customizations";

import "./style.css";
import background from "./../../images/background.jpg";
import logo from "./../../images/logo.png";

class SignupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        termsNConditions: false
      },
      signupInfo: {
        eventInfo: "",
        box1Title: "",
        box2Title: "",
        box1Desc: "",
        box2Desc: ""
      },
      isConditionReading: false,
      accessId: 1
    };
    this.props.getSignupInfo(this.props.token, this.state.accessId);
  }

  handleChange = event => {
    let attribute = event.target.getAttribute("data-state");
    let value = event.target.value;

    if (attribute === "termsNConditions") {
      if (value === "true") {
        value = true;
      } else if (value === "false") {
        value = false;
      }
    }

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [attribute]: value
      }
    });
  };

  handleMessage = () => {
    if (this.props.error !== "") {
      return (
        <Col>
          <UncontrolledAlert className="text-left" color="danger">
            <strong>Error:</strong> {this.props.error}
          </UncontrolledAlert>
        </Col>
      );
    } else if (this.props.success !== "") {
      return (
        <Col>
          <UncontrolledAlert className="text-left" color="success ">
            <strong>Success:</strong> {this.props.success}
          </UncontrolledAlert>
        </Col>
      );
    }
  };

  conditionReadingToggle = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      isConditionReading: !this.state.isConditionReading
    });
  };

  termsNConditions = () => (
    <Modal
      isOpen={this.state.isConditionReading}
      toggle={this.conditionReadingToggle}
      className={this.props.className}
    >
      <ModalHeader toggle={this.conditionReadingToggle}>
        <span className="display-5">Terms & Conditions</span>
      </ModalHeader>
      <ModalBody>
        <p className="text-left text-medium">
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the
          https://ballflightuniversity.com website (the "Service") operated by
          Flight Scope ("us", "we", or "our"). Your access to and use of the
          Service is conditioned on your acceptance of and compliance with these
          Terms. These Terms apply to all visitors, users and others who access
          or use the Service.
        </p>
        <strong>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </strong>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary btn-auto"
          onClick={this.conditionReadingToggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );

  handleSubmit = event => {
    event.preventDefault();
    this.props.submit(this.state.form);
  };

  render = () => {
    if (
      Object.keys(this.props.GetSignupInfo).length > 0 &&
      "status" in this.props.GetSignupInfo
    ) {
      if (
        this.props.GetSignupInfo.status &&
        Object.keys(this.props.GetSignupInfo.data).length > 0
      ) {
        this.setState({
          ...this.state,
          signupInfo: {
            ...this.state.signupInfo,
            eventInfo: this.props.GetSignupInfo.data.eventInfo,
            box1Title: this.props.GetSignupInfo.data.box1Title,
            box2Title: this.props.GetSignupInfo.data.box2Title,
            box1Desc: this.props.GetSignupInfo.data.box1Desc,
            box2Desc: this.props.GetSignupInfo.data.box2Desc
          }
        });
        this.props.getSignupInfo(this.props.token);
      }
    }

    return (
      <div id="signup-page" style={{ backgroundImage: `url(${background})` }}>
        <Container fluid={true}>
          <Row>
            {this.props.event && (
              <Col sm="3" className="signupdayinfo">
                <Card>
                  <CardHeader className="schedulecardhead">
                    {/* { Schedule - Day 1} */}
                    {this.state.signupInfo.box1Title}
                  </CardHeader>
                  <CardBody className="schedulecardbody">
                    {/* {<CardTitle className="text-medium schedulecardtitle">
                      9:00AM-9:30AM: Opening Introduction
                    </CardTitle>
                    <CardText className="text-small ">
                      <ul>
                        <li>
                          What is FlightScope University? What is the
                          certification Process?
                        </li>
                        <li>What will you learn? Why? </li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      9:30AM-12:00PM: Intro to Scoring and Pillar System
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>
                          Step 1- Gather Data/Document Student Baseline/Identify
                          Strengths & Improvement Opportunities
                        </li>
                        <li>
                          Step 2- Score Data Results/Club Data Elements/Ball
                          Data Elements 4 Scoring Categories 0/.25/.50/1.0
                        </li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      12:00PM-1:00PM: Station 1- FlightScope
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>FlightScope Equipment/FlightScope Apps</li>
                        <li>
                          Registration, calibration, best practices,
                          trouble-shooting
                        </li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      1:00PM-2:00PM: Lunch
                    </CardTitle>

                    <CardTitle className="text-medium schedulecardtitle">
                      2:00-3:00PM: Station 2- Full Swing
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>
                          Measure/Score/Determine Pillar and Set course of
                          Action
                        </li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      3:00-4:00PM: Station 3- Short Game
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>
                          Measure/Score/Determine Pillar and Set course of
                          Action
                        </li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      4:00-5:00PM: Station 4
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>
                          Measure/Score/Determine Pillar and Set course of
                          Action
                        </li>
                      </ul>
                    </CardText>} */}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.signupInfo.box1Desc
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col sm={{ size: 6, offset: this.props.event ? 0 : 3 }}>
              <div className="form-heading text-center mt-5 mb-4">
                <a href="/#/">
                  <img src={logo} alt="Logo" />
                </a>
              </div>
              <div className="signup-form text-center">
                <Col>
                  <h1 className="display-4">Sign Up</h1>
                  {this.props.event && (
                    // {      <p className="text-small" style={{ color: "#000" }}>
                    //         <b>
                    //           For April: 22,23rd Country Club of Winter Haven at
                    //           Orlando, Florida
                    //         </b>
                    //         <br />
                    //         Country Club of Winter Haven 4200 Country Club Rd S,
                    //         Winter Haven, FL 33881 Ph.(703) 618-4653
                    //       </p>}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.signupInfo.eventInfo
                      }}
                    />
                  )}
                </Col>
                {this.handleMessage()}
                <Col>
                  <Form id="signup" onSubmit={this.handleSubmit}>
                    <Row>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Input
                            type="text"
                            name="first-name"
                            data-state="firstName"
                            className="form-control"
                            placeholder="First Name"
                            value={this.state.form.firstName}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Input
                            type="text"
                            name="last-name"
                            data-state="lastName"
                            className="form-control"
                            placeholder="Last Name"
                            value={this.state.form.lastName}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Input
                            type="email"
                            name="email"
                            data-state="email"
                            className="form-control"
                            placeholder="Email Address"
                            value={this.state.form.email}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          {/* <Input
                      type="text"
                      name="phone"
                      data-state="phone"
                      className="form-control"
                      placeholder="Phone Number ( eg. XXX-XXX-XXXX)"
                      value={this.state.form.phone}
                      onChange={this.handleChange}
                    /> */}
                          <Cleave
                            name="phone"
                            data-state="phone"
                            className="form-control"
                            placeholder="Phone Number"
                            value={this.state.form.phone}
                            options={{
                              delimiter: "-",
                              blocks: [3, 3, 4]
                            }}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Input
                            type="password"
                            name="password"
                            data-state="password"
                            className="form-control"
                            placeholder="Password"
                            value={this.state.form.password}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Input
                            type="password"
                            name="confirm-password"
                            data-state="confirmPassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            value={this.state.form.confirmPassword}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4 text-left">
                      <Col>
                        <div className="form-check">
                          <label className="ml-2">
                            <Input
                              type="checkbox"
                              name="terms-n-conditions"
                              data-state="termsNConditions"
                              value={!this.state.form.termsNConditions}
                              checked={this.state.form.termsNConditions}
                              onChange={this.handleChange}
                            />
                            <span>
                              I Read and Accept the{" "}
                              <a
                                onClick={this.conditionReadingToggle}
                                className="pointer"
                              >
                                Terms & Conditions
                              </a>
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Button
                      color="info"
                      disabled={!this.state.form.termsNConditions}
                    >
                      Sign Up
                    </Button>
                  </Form>
                  <div className="card-footer mt-3 text-medium">
                    Already have an account?&nbsp;
                    <a href="/#/login">Login</a>
                  </div>
                </Col>
              </div>
              {this.termsNConditions()}
            </Col>
            {this.props.event && (
              <Col sm="3" className="signupdayinfo">
                <Card>
                  <CardHeader className="schedulecardhead">
                    {/* {  Schedule - Day 2} */}
                    {this.state.signupInfo.box2Title}
                  </CardHeader>
                  <CardBody className="schedulecardbody">
                    {/* { <CardTitle className="text-medium schedulecardtitle">
                      9:00AM-9:30AM: Day 1 Review/Questions
                    </CardTitle>

                    <CardTitle className="text-medium schedulecardtitle">
                      9:30AM-10:30AM: Station 1- FlightScope
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>FlightScope Combine and Apps</li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      10:30AM-11:30AM: Station 2- Full Swing
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>Small group workshops</li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      11:30AM-12:30AM: Station 3- Short Game
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>Small group workshops</li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      12:30AM-1:30AM: Station 4- Putting
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>Small group workshops</li>
                      </ul>
                    </CardText>

                    <CardTitle className="text-medium schedulecardtitle">
                      1:30PM-2:30PM: Lunch
                    </CardTitle>

                    <CardTitle className="text-medium schedulecardtitle">
                      2:30PM-4:30PM: Intro to Certified Performance Coaching
                      (CPC)
                    </CardTitle>
                    <CardText className="text-small">
                      <ul>
                        <li>Intro to Personalities G-O-L-F</li>
                        <li>Intro to Practice Levels</li>
                        <li>Intro to Process/Rule of Three</li>
                        <li>Intro to Ball Speed Laws</li>
                      </ul>
                    </CardText>
                    <CardTitle className="text-medium schedulecardtitle">
                      4:30PM-5:00PM: FlightScope Certification Summary/Survey
                    </CardTitle>} */}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.signupInfo.box2Desc
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    GetSignupInfo: state.GetSignupInfo
  };
};

export default connect(
  mapStateToProps,
  { getSignupInfo }
)(SignupContainer);
