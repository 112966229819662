// START:  Define Constants For Theme Customization Module.
export const GET_CUSTOMIZATIONS_BEGIN = "get_customizations_begin";
export const GET_CUSTOMIZATIONS_REQUESTING = "get_customizations_requesting";
export const GET_CUSTOMIZATIONS_SUCCESS = "get_customizations_success";
export const GET_CUSTOMIZATIONS_ERROR = "get_customizations_error";

export const GET_CUSTOMIZATION_BEGIN = "get_customization_begin";
export const GET_CUSTOMIZATION_REQUESTING = "get_customization_requesting";
export const GET_CUSTOMIZATION_SUCCESS = "get_customization_success";
export const GET_CUSTOMIZATION_ERROR = "get_customization_error";
export const GET_CUSTOMIZATION_RESET = "get_customization_reset";

export const SET_CUSTOMIZATION_BEGIN = "set_customization_begin";
export const SET_CUSTOMIZATION_REQUESTING = "set_customization_requesting";
export const SET_CUSTOMIZATION_SUCCESS = "set_customization_success";
export const SET_CUSTOMIZATION_ERROR = "set_customization_error";
export const SET_CUSTOMIZATION_RESET = "set_customization_reset";

// END:  Define Constants For Theme Customization Module.

// START:  Define Constants For Theme Customization of General Settings
export const GET_GENERAL_BEGIN = "get_general_begin";
export const GET_GENERAL_REQUESTING = "get_general_requesting";
export const GET_GENERAL_SUCCESS = "get_general_success";
export const GET_GENERAL_ERROR = "get_general_error";
export const GET_GENERAL_RESET = "get_general_reset";
export const GET_GENERALS_SUCCESS = "get_generals_success";

export const UPDATE_GENERAL_BEGIN = "update_general_begin";
export const UPDATE_GENERAL_REQUESTING = "update_general_requesting";
export const UPDATE_GENERAL_SUCCESS = "update_general_success";
export const UPDATE_GENERAL_ERROR = "update_general_error";
export const UPDATE_GENERAL_RESET = "update_general_reset";

// END:  Define Constants For Theme Customization of General Settings

// START:  Define Constants For Theme Customization of Gallery Sections
export const GET_GALLERYS_BEGIN = "get_gallerys_begin";
export const GET_GALLERYS_REQUESTING = "get_gallerys_requesting";
export const GET_GALLERYS_SUCCESS = "get_gallerys_success";
export const GET_GALLERYS_ERROR = "get_gallerys_error";
export const GET_GALLERYS_RESET = "get_gallerys_reset";

export const GET_GALLERY_BEGIN = "get_gallery_begin";
export const GET_GALLERY_REQUESTING = "get_gallery_requesting";
export const GET_GALLERY_SUCCESS = "get_gallery_success";
export const GET_GALLERY_ERROR = "get_gallery_error";
export const GET_GALLERY_RESET = "get_gallery_reset";

export const SET_GALLERY_BEGIN = "set_gallery_begin";
export const SET_GALLERY_REQUESTING = "set_gallery_requesting";
export const SET_GALLERY_SUCCESS = "set_gallery_success";
export const SET_GALLERY_ERROR = "set_gallery_error";
export const SET_GALLERY_RESET = "set_gallery_reset";

export const UPDATE_GALLERY_BEGIN = "update_gallery_begin";
export const UPDATE_GALLERY_REQUESTING = "update_gallery_requesting";
export const UPDATE_GALLERY_SUCCESS = "update_gallery_success";
export const UPDATE_GALLERY_ERROR = "update_gallery_error";
export const UPDATE_GALLERY_RESET = "update_gallery_reset";

export const DELETE_GALLERY_BEGIN = "delete_gallery_begin";
export const DELETE_GALLERY_REQUESTING = "delete_gallery_requesting";
export const DELETE_GALLERY_SUCCESS = "delete_gallery_success";
export const DELETE_GALLERY_ERROR = "delete_gallery_error";
export const DELETE_GALLERY_RESET = "delete_gallery_reset";

// END:  Define Constants For Theme Customization of Gallery Sections

// START:  Define Constants For Theme Customization of Menu Sections
export const GET_MENUS_BEGIN = "get_menus_begin";
export const GET_MENUS_REQUESTING = "get_menus_requesting";
export const GET_MENUS_SUCCESS = "get_menus_success";
export const GET_MENUS_ERROR = "get_menus_error";
export const GET_MENUS_RESET = "get_menus_reset";

export const GET_MENU_BEGIN = "get_menu_begin";
export const GET_MENU_REQUESTING = "get_menu_requesting";
export const GET_MENU_SUCCESS = "get_menu_success";
export const GET_MENU_ERROR = "get_menu_error";
export const GET_MENU_RESET = "get_menu_reset";

export const SET_MENU_BEGIN = "set_menu_begin";
export const SET_MENU_REQUESTING = "set_menu_requesting";
export const SET_MENU_SUCCESS = "set_menu_success";
export const SET_MENU_ERROR = "set_menu_error";
export const SET_MENU_RESET = "set_menu_reset";

export const UPDATE_MENU_BEGIN = "update_menu_begin";
export const UPDATE_MENU_REQUESTING = "update_menu_requesting";
export const UPDATE_MENU_SUCCESS = "update_menu_success";
export const UPDATE_MENU_ERROR = "update_menu_error";
export const UPDATE_MENU_RESET = "update_menu_reset";

export const DELETE_MENU_BEGIN = "delete_menu_begin";
export const DELETE_MENU_REQUESTING = "delete_menu_requesting";
export const DELETE_MENU_SUCCESS = "delete_menu_success";
export const DELETE_MENU_ERROR = "delete_menu_error";
export const DELETE_MENU_RESET = "delete_menu_reset";

// END:  Define Constants For Theme Customization of Menu Sections

// START:  Define Constants For Theme Customization of Testimonials Sections
export const GET_TESTIMONIALS_BEGIN = "get_testimonials_begin";
export const GET_TESTIMONIALS_REQUESTING = "get_testimonials_requesting";
export const GET_TESTIMONIALS_SUCCESS = "get_testimonials_success";
export const GET_TESTIMONIALS_ERROR = "get_testimonials_error";
export const GET_TESTIMONIALS_RESET = "get_testimonials_reset";

export const GET_TESTIMONIAL_BEGIN = "get_testimonial_begin";
export const GET_TESTIMONIAL_REQUESTING = "get_testimonial_requesting";
export const GET_TESTIMONIAL_SUCCESS = "get_testimonial_success";
export const GET_TESTIMONIAL_ERROR = "get_testimonial_error";
export const GET_TESTIMONIAL_RESET = "get_testimonial_reset";

export const SET_TESTIMONIAL_BEGIN = "set_testimonial_begin";
export const SET_TESTIMONIAL_REQUESTING = "set_testimonial_requesting";
export const SET_TESTIMONIAL_SUCCESS = "set_testimonial_success";
export const SET_TESTIMONIAL_ERROR = "set_testimonial_error";
export const SET_TESTIMONIAL_RESET = "set_testimonial_reset";

export const UPDATE_TESTIMONIAL_BEGIN = "update_testimonial_begin";
export const UPDATE_TESTIMONIAL_REQUESTING = "update_testimonial_requesting";
export const UPDATE_TESTIMONIAL_SUCCESS = "update_testimonial_success";
export const UPDATE_TESTIMONIAL_ERROR = "update_testimonial_error";
export const UPDATE_TESTIMONIAL_RESET = "update_testimonial_reset";

export const DELETE_TESTIMONIAL_BEGIN = "delete_testimonial_begin";
export const DELETE_TESTIMONIAL_REQUESTING = "delete_testimonial_requesting";
export const DELETE_TESTIMONIAL_SUCCESS = "delete_testimonial_success";
export const DELETE_TESTIMONIAL_ERROR = "delete_testimonial_error";
export const DELETE_TESTIMONIAL_RESET = "delete_testimonial_reset";

// END:  Define Constants For Theme Customization of Testimonials Sections

// START:  Define Constants For Theme Customization of Banner Settings
export const GET_BANNER_BEGIN = "get_banner_begin";
export const GET_BANNER_REQUESTING = "get_banner_requesting";
export const GET_BANNER_SUCCESS = "get_banner_success";
export const GET_BANNER_ERROR = "get_banner_error";
export const GET_BANNER_RESET = "get_banner_reset";
export const GET_BANNERS_SUCCESS = "get_banners_success";

export const UPDATE_BANNER_BEGIN = "update_banner_begin";
export const UPDATE_BANNER_REQUESTING = "update_banner_requesting";
export const UPDATE_BANNER_SUCCESS = "update_banner_success";
export const UPDATE_BANNER_ERROR = "update_banner_error";
export const UPDATE_BANNER_RESET = "update_banner_reset";

// END:  Define Constants For Theme Customization of Banner Settings

// START:  Define Constants For Theme Customization of News Event Sections
export const GET_NEWS_EVENTS_BEGIN = "get_news_events_begin";
export const GET_NEWS_EVENTS_REQUESTING = "get_news_events_requesting";
export const GET_NEWS_EVENTS_SUCCESS = "get_news_events_success";
export const GET_NEWS_EVENTS_ERROR = "get_news_events_error";

export const GET_NEWS_EVENT_BEGIN = "get_news_event_begin";
export const GET_NEWS_EVENT_REQUESTING = "get_news_event_requesting";
export const GET_NEWS_EVENT_SUCCESS = "get_news_event_success";
export const GET_NEWS_EVENT_ERROR = "get_news_event_error";
export const GET_NEWS_EVENT_RESET = "get_news_event_reset";

export const SET_NEWS_EVENT_BEGIN = "set_news_event_begin";
export const SET_NEWS_EVENT_REQUESTING = "set_news_event_requesting";
export const SET_NEWS_EVENT_SUCCESS = "set_news_event_success";
export const SET_NEWS_EVENT_ERROR = "set_news_event_error";
export const SET_NEWS_EVENT_RESET = "set_news_event_reset";

export const UPDATE_NEWS_EVENT_BEGIN = "update_news_event_begin";
export const UPDATE_NEWS_EVENT_REQUESTING = "update_news_event_requesting";
export const UPDATE_NEWS_EVENT_SUCCESS = "update_news_event_success";
export const UPDATE_NEWS_EVENT_ERROR = "update_news_event_error";
export const UPDATE_NEWS_EVENT_RESET = "update_news_event_reset";

export const DELETE_NEWS_EVENT_BEGIN = "delete_news_event_begin";
export const DELETE_NEWS_EVENT_REQUESTING = "delete_news_event_requesting";
export const DELETE_NEWS_EVENT_SUCCESS = "delete_news_event_success";
export const DELETE_NEWS_EVENT_ERROR = "delete_news_event_error";
export const DELETE_NEWS_EVENT_RESET = "delete_news_event_reset";

// END:  Define Constants For Theme Customization of News Event Sections

// START:  Define Constants For Theme Customization of Discount Setting

export const GET_DISCOUNT_BEGIN = "get_discount_begin";
export const GET_DISCOUNT_REQUESTING = "get_discount_requesting";
export const GET_DISCOUNT_SUCCESS = "get_discount_success";
export const GET_DISCOUNT_ERROR = "get_discount_error";
export const GET_DISCOUNT_RESET = "get_discount_reset";

export const UPDATE_DISCOUNT_BEGIN = "update_discount_begin";
export const UPDATE_DISCOUNT_REQUESTING = "update_discount_requesting";
export const UPDATE_DISCOUNT_SUCCESS = "update_discount_success";
export const UPDATE_DISCOUNT_ERROR = "update_discount_error";
export const UPDATE_DISCOUNT_RESET = "update_discount_reset";

// END:  Define Constants For Theme Customization of Discount Setting

// START:  Define Constants For Theme Customization of Signup Info Setting

export const GET_SIGNUP_INFO_BEGIN = "get_signup_info_begin";
export const GET_SIGNUP_INFO_REQUESTING = "get_signup_info_requesting";
export const GET_SIGNUP_INFO_SUCCESS = "get_signup_info_success";
export const GET_SIGNUP_INFO_ERROR = "get_signup_info_error";
export const GET_SIGNUP_INFO_RESET = "get_signup_info_reset";

export const UPDATE_SIGNUP_INFO_BEGIN = "update_signup_info_begin";
export const UPDATE_SIGNUP_INFO_REQUESTING = "update_signup_info_requesting";
export const UPDATE_SIGNUP_INFO_SUCCESS = "update_signup_info_success";
export const UPDATE_SIGNUP_INFO_ERROR = "update_signup_info_error";
export const UPDATE_SIGNUP_INFO_RESET = "update_signup_info_reset";

// END:  Define Constants For Theme Customization of Signup Info Setting
