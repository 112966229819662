/*  import react packages */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/*  import Designing Constant */
import FeatherIcon from 'feather-icons-react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledAlert
} from 'reactstrap';

/*  import a javascript file */

/*  import consumable API functions */
import {
  getSignupInfo,
  updateSignupInfo
} from '../../../actions/modules_action/customizations';

/* Define a class Based Component */
class SignupInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        eventInfo: EditorState.createEmpty(),
        box1Title: '',
        box2Title: '',
        box1Desc: EditorState.createEmpty(),
        box2Desc: EditorState.createEmpty()
      },
      uploadProperty: '',
      formSuccessMessage: '',
      formErrorMessage: '',
      accessId: 1,
      uploadError: ''
    };
    this.props.getSignupInfo(this.props.token, this.state.accessId);
  }

  // handleChange method is used to handle the user input's when event occured.
  handleChange = event => {
    let attribute = event.target.getAttribute('data-state');
    let value = event.target.value;

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [attribute]: value
      }
    });
  };

  onEditorStateChange = (editorState, boxName) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [boxName]: editorState
      }
    });
  };

  // handleSubmit method is used to handle the form submitation.
  handleSubmit = event => {
    event.preventDefault();
    const form = Object.assign({}, this.state.form);

    form.eventInfo = draftToHtml(
      convertToRaw(form.eventInfo.getCurrentContent())
    );
    form.box1Desc = draftToHtml(
      convertToRaw(form.box1Desc.getCurrentContent())
    );
    form.box2Desc = draftToHtml(
      convertToRaw(form.box2Desc.getCurrentContent())
    );
    this.props.updateSignupInfo(this.props.token, this.state.accessId, form);
  };

  convertToDraft = html => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    return EditorState.createWithContent(contentState);
  };

  // START: Component rendering
  render = () => {
    let error = '';
    let accessData = {};

    // Used to display records
    if (
      Object.keys(this.props.GetSignupInfo).length > 0 &&
      'status' in this.props.GetSignupInfo
    ) {
      if (
        this.props.GetSignupInfo.status &&
        Object.keys(this.props.GetSignupInfo.data).length > 0
      ) {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            eventInfo: this.convertToDraft(
              this.props.GetSignupInfo.data.eventInfo
            ),
            box1Title: this.props.GetSignupInfo.data.box1Title,
            box2Title: this.props.GetSignupInfo.data.box2Title,
            box1Desc: this.convertToDraft(
              this.props.GetSignupInfo.data.box1Desc
            ),
            box2Desc: this.convertToDraft(
              this.props.GetSignupInfo.data.box2Desc
            )
          }
        });
        this.props.getSignupInfo(this.props.token);
      } else {
        this.setState({
          ...this.state,
          formErrorMessage: 'Something Wrong.'
        });
      }
    }

    // Used to update record.
    if (
      Object.keys(this.props.UpdateSignupInfo).length > 0 &&
      'status' in this.props.UpdateSignupInfo
    ) {
      if (this.props.UpdateSignupInfo.status) {
        if (this.state.formSuccessMessage === '') {
          this.setState({
            ...this.state,
            formSuccessMessage: 'Signup event info Updated Successfully.',
            accessId: 1,
            isFormEditable: false,
            formErrorMessage: ''
          });
          this.props.getSignupInfo(this.props.token, this.state.accessId);
        }
      } else {
        this.setState({
          ...this.state,
          formSuccessMessage: '',
          formErrorMessage: this.props.UpdateSignupInfo.message || ''
        });
      }
      this.props.updateSignupInfo(this.props.token, this.state.accessId);
    }

    return (
      <div className="Discount-form text-medium">
        <Col>
          {this.state.formSuccessMessage !== '' && (
            <UncontrolledAlert className="text-left" color="success">
              <strong>Success:</strong> {this.state.formSuccessMessage}
            </UncontrolledAlert>
          )}
          {this.state.formErrorMessage !== '' && (
            <UncontrolledAlert className="text-left" color="danger">
              <strong>Error:</strong> {this.state.formErrorMessage}
            </UncontrolledAlert>
          )}
          {/* {this.props.error !== '' && <UncontrolledAlert className="text-left" color="danger">
                            <strong>Error:</strong> {this.props.error}
                        </UncontrolledAlert>} */}
          <Form id="Discount" onSubmit={this.handleSubmit}>
            <Row>
              <Col>
                <FormGroup row>
                  <Label for="subscriptionDiscount" sm={3}>
                    Sign Up Box 1 Title
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="box1Title"
                      data-state="box1Title"
                      className="form-control"
                      placeholder="Enter the title"
                      value={this.state.form.box1Title}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="subscriptionDiscount" sm={3}>
                    Sign Up Box 1 Description
                  </Label>
                  <Col sm={9}>
                    <Editor
                      editorState={this.state.form.box1Desc}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="textEditorWrapper"
                      editorClassName="textEditor"
                      onEditorStateChange={editorState =>
                        this.onEditorStateChange(editorState, 'box1Desc')
                      }
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="subscriptionDiscount" sm={3}>
                    Sign Up Box 2 Title
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="box2Title"
                      data-state="box2Title"
                      className="form-control"
                      placeholder="Enter the title"
                      value={this.state.form.box2Title}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="subscriptionDiscount" sm={3}>
                    Sign Up Box 2 Description
                  </Label>
                  <Col sm={9}>
                    <Editor
                      editorState={this.state.form.box2Desc}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="textEditorWrapper"
                      editorClassName="textEditor"
                      onEditorStateChange={editorState =>
                        this.onEditorStateChange(editorState, 'box2Desc')
                      }
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="subscriptionDiscount" sm={3}>
                    Event Info
                  </Label>
                  <Col sm={9}>
                    <Editor
                      editorState={this.state.form.eventInfo}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="textEditorWrapper"
                      editorClassName="textEditor"
                      onEditorStateChange={editorState =>
                        this.onEditorStateChange(editorState, 'eventInfo')
                      }
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Button
                      color="info btn-auto"
                      className="ml-2 float-right"
                      onClick={this.handleSubmit}
                    >
                      <FeatherIcon icon="save" size="20" className="mb-1" />{' '}
                      Save
                    </Button>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    );
  };
  // END: Component rendering
}

const mapStateToProps = state => {
  return {
    GetSignupInfo: state.GetSignupInfo,
    UpdateSignupInfo: state.UpdateSignupInfo
  };
};

export default connect(
  mapStateToProps,
  { getSignupInfo, updateSignupInfo }
)(SignupInfoForm);
