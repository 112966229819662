import React, { Component } from "react";
import { connect } from "react-redux";

import FeatherIcon from "feather-icons-react";
import { Container, Col } from "reactstrap";

import { getSubscriptions } from "./../../actions/modules_action/subscriptions";
import Subscription from "../common/subscription";
import "./style.css";
import background from "./../../images/background.jpg";
import logo from "./../../images/logo.png";

class RegisterSuccess extends Component {
  constructor(props) {
    super(props);
    props.getSubscriptions();
    this.congratulationRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(
      () =>
        window.scrollTo({
          behavior: "smooth",
          top: this.congratulationRef.current.offsetTop
        }),
      1500
    );
  }

  componentWillUnmount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  }

  render = () => {
    let subscriptionsList = [];
    if (
      Object.keys(this.props.GetSubscriptions).length > 0 &&
      "status" in this.props.GetSubscriptions
    ) {
      if (this.props.GetSubscriptions.status) {
        subscriptionsList = this.props.GetSubscriptions.data;
      }
    }

    return (
      <div
        id="register-success-page"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Container>
          <div className="form-heading text-center mt-5 mb-4">
            <a href="/#/">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          <div
            className="register-success-wrapper text-center"
            ref={this.congratulationRef}
          >
            <Col>
              <FeatherIcon
                icon="check-circle"
                size="50"
                className="mb-2 mt-3 text-success"
              />
              <br />
              <h1 className="display-4">Congratulations!</h1>
              <p className="text-large text-center mt-4">
                Your account has been created.
              </p>
            </Col>
            {this.props.event && (
              <Subscription
                loggedIn={this.props.loggedIn}
                userId={this.props.userId}
                token={this.props.token}
                subscriptionId={this.props.subscriptionId}
                subscriptions={subscriptionsList}
                newUser={true}
              />
            )}
            <Col>
              <br />
              Go to{" "}
              <a href={`/#/profile/dashboard/${this.props.token}`}>Dashboard</a>
              <br />
            </Col>
          </div>
        </Container>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    GetSubscriptions: state.GetSubscriptions
  };
};

export default connect(
  mapStateToProps,
  { getSubscriptions }
)(RegisterSuccess);
